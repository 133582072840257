import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
import Styles from './ProductListingItem.module.css'

const ProductsListingItem = ({ product }) => {

  const {
    images: [firstImage],
  } = product

  var length = product.variants.length;
  var lowest = 0;

  for (var i = 0; i < length; i++) {
    if (i === 0) {
      lowest = product.variants[0].price;
    }

    if (Number(lowest) > Number(product.variants[i].price)) {

      lowest = product.variants[i].price;
    }

  }


  return (
    <div itemScope itemType="https://schema.org/Product">
      <Link to={`/product/${product.handle}`}>
        <div className={Styles.card}>
          <Image fluid={firstImage.localFile.childImageSharp.fluid} itemProp="image" />
          <div className={Styles.cardcontainer}>

            <h2 className={Styles.cardProductTitle} itemProp="name">{product.title}</h2>

            <p itemProp="offers" itemScope itemType="https://schema.org/Offer"><span itemProp="priceCurrency">$</span><span itemProp="price">{lowest}</span></p>
          </div>
        </div>
      </Link>



    </div>
  )
}

export default ProductsListingItem
